<template>
  <div class="AutomaticSpeechRecognition">
    <img src="../../assets/imges/succedaneum.png" class="headerimg" />

    <div class="subproduct">
      <div class="title">产品子功能</div>
      <div class="subproductlist">
        <div class="subproductcard">
          <div class="subproductimg">
            <img src="../../assets/product/AutomaticSpeechRecognition/2.png" />
          </div>
          <div class="subproductinfo">
            <div class="subproducttitle">实时语音识别</div>
            <div class="subproducttext">
              对不限时长的实时音频流进行识别，识别结果自动断句，标记每句话的开始和结束时间；可应用于智能语音助手等实时音频流场景。
            </div>
          </div>
        </div>
        <div class="subproductcard">
          <div class="subproductimg">
            <img src="../../assets/product/AutomaticSpeechRecognition/3.png" />
          </div>
          <div class="subproductinfo">
            <div class="subproducttitle">录音文件识别</div>
            <div class="subproducttext">
              对用户上传的五小时之内的录音文件进行识别；可应用于呼叫中心语音质检、视频字幕生成，会议语音资料转写等场景。
            </div>
          </div>
        </div>
      </div>

      <div class="subproductlist">
        <div class="subproductcard">
          <div class="subproductimg">
            <img src="../../assets/product/AutomaticSpeechRecognition/4.png" />
          </div>
          <div class="subproductinfo">
            <div class="subproducttitle">语音流异步识别</div>
            <div class="subproducttext">
              对直播协议语音流进行识别，准实时返回识别结果，支持音视频场景专用模型；可用于直播流质检等场景
            </div>
          </div>
        </div>
        <div class="subproductcard">
          <div class="subproductimg">
            <img src="../../assets/product/AutomaticSpeechRecognition/5.png" />
          </div>
          <div class="subproductinfo">
            <div class="subproducttitle">录音文件识别极速版</div>
            <div class="subproducttext">
              对用户上传的较大的录音文件进行极速识别，半实时返回识别结果；可应用于音视频字幕、准实时质检分析场景等场景。
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="advantage">
      <div class="title">产品优势</div>

      <div class="advantagelist thtop">
        <div class="advantagecard thright thpaddingright2 thbottom">
          <div class="advantageimg">
            <img src="../../assets/product/AutomaticSpeechRecognition/6.png" />
          </div>
          <div class="advantagetitle">价格低</div>
          <div class="advantageinfo">
            购买预付费包更加优惠，识别1小时语音不到1元。每月赠送免费额度，降低客户成本。
          </div>
        </div>
        <div
          class="advantagecard thright thpaddingright thpaddingleft thbottom"
        >
          <div class="advantageimg">
            <img src="../../assets/product/AutomaticSpeechRecognition/7.png" />
          </div>
          <div class="advantagetitle">效果好</div>
          <div class="advantageinfo">
            字准率97%，与微信、王者荣耀的语音转文字使用一套服务，效果一样好。
          </div>
        </div>
        <div class="advantagecard thpaddingleft thbottom">
          <div class="advantageimg">
            <img src="../../assets/product/AutomaticSpeechRecognition/8.png" />
          </div>
          <div class="advantagetitle">语种多</div>
          <div class="advantageinfo">
            已经支持中文普通话、英语、粤语、韩语、日语、泰语和上海话方言的语音识别，后续将陆续开放其他语种和方言的识别能力。
          </div>
        </div>
      </div>
      <div class="advantagelist">
        <div class="advantagecard thright thpaddingright2 thtop">
          <div class="advantageimg">
            <img src="../../assets/product/AutomaticSpeechRecognition/9.png" />
          </div>
          <div class="advantagetitle">算法强大</div>
          <div class="advantageinfo">
            基于创新网络结构 TLC-BLSTM，利用 ATTENTION
            机制有效地对语音信号进行建模，通过 Teacher-Student
            方式提升系统鲁棒性，对通用以及垂直领域下场景的识别精度和效率。
          </div>
        </div>
        <div class="advantagecard thright thpaddingright thpaddingleft thtop">
          <div class="advantageimg">
            <img src="../../assets/product/AutomaticSpeechRecognition/10.png" />
          </div>
          <div class="advantagetitle">自助提升准确率</div>
          <div class="advantageinfo">
            针对垂直领域，上传词表或句子即可完成语言模型的自动优化，借助自训练平台，不懂算法也可轻松实现定制化模型，进一步提升识别准确率。
          </div>
        </div>
        <div class="advantagecard thpaddingleft thtop">
          <div class="advantageimg">
            <img src="../../assets/product/AutomaticSpeechRecognition/11.png" />
          </div>
          <div class="advantagetitle">支持场景丰富</div>
          <div class="advantageinfo">
            经过微信、腾讯视频、王者荣耀等大流量产品的充分验证，在互联网、金融、教育等领域，基于海量数据实现分场景优化，积累了多行业的实践经验。
          </div>
        </div>
      </div>
    </div>

    <div style="background: #f5f5f9">
      <div class="adhibition">
        <div class="title">应用场景</div>
        <div class="adhibitioninfo top">
          <div class="adhibitiontitle">业务痛点</div>
          <div class="adhibitiontext">
            在拍摄 Vlog
            的场景中，用户会边拍视频边说话；拍完视频还需要二次编辑，手动输入字幕才能将视频发布。
          </div>
        </div>
        <div class="adhibitioninfo top2">
          <div class="adhibitiontitle">解决方案</div>
          <div class="adhibitiontext">
            通过录音文件识别极速版，实现了用户边拍边说，将语音内容直接显示在视频上。
          </div>
        </div>
        <div class="adhibitionimg">
          <img src="../../assets/product/AutomaticSpeechRecognition/12.png" />
        </div>
      </div>
    </div>

    <div class="case">
      <div class="title">客户案例</div>
      <div class="list top">
        <div class="card">
          <img src="../../assets/product/AutomaticSpeechRecognition/13.png" />
          <div class="cardtitle">微信</div>
          <div class="info">
            在语音消息转写和语音输入产品功能中使用语音识别服务，帮助用户更便捷的进行消息阅览和传递，提升了
            App 的易用性。
          </div>
          <div class="bebottom"></div>
        </div>
        <div class="card">
          <img src="../../assets/product/AutomaticSpeechRecognition/14.png" />
          <div class="cardtitle">喜马拉雅</div>
          <div class="info">
            喜马拉雅使用语音识别，实现 UGC
            上传音频节目转写，通过转写为文字的内容提供内容标签，实现更精准的内容推荐
          </div>
          <div class="bebottom"></div>
        </div>
        <div class="card">
          <img src="../../assets/product/AutomaticSpeechRecognition/15.png" />
          <div class="cardtitle">珍爱网</div>
          <div class="info">
            使用语音识别完成电话录音质检，效率提升31倍，成本降低90%，过去50个人的工作量，现在只需1个人工+1台机器。
          </div>
          <div class="bebottom"></div>
        </div>
      </div>
      <div class="list">
        <div class="card">
          <img src="../../assets/product/AutomaticSpeechRecognition/16.png" />
          <div class="cardtitle">58同城</div>
          <div class="info">
            58同城智能电话联络系统集成语音识别服务，使得电话联络机器人可以听懂客户的声音，显著提高电话联络效率，降低电话联络人力成本。
          </div>
          <div class="bebottom"></div>
        </div>
        <div class="card">
          <img src="../../assets/product/AutomaticSpeechRecognition/17.png" />
          <div class="cardtitle">中信银行</div>
          <div class="info">
            中信银行手机银行 App
            集成语音识别服务，用户使用语音交互，即可完成转账、查询、理财等日常金融交易，大幅提升业务办理效率。
          </div>
          <div class="bebottom"></div>
        </div>
        <div class="card">
          <img src="../../assets/product/AutomaticSpeechRecognition/18.png" />
          <div class="cardtitle">尚德机构</div>
          <div class="info">
            尚德机构使用语音识别进行电话录音质检，从只能抽检变成全检，大幅提高质检效率，完成客服工作质量评价，并推动改进客服与用户沟通的体验。
          </div>
          <div class="bebottom"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.AutomaticSpeechRecognition {
  .subproduct {
    background: #f5f5f9;
    padding-top: 140px;
    padding-bottom: 89px;

    .subproductlist {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      margin-top: 91px;

      .subproductimg {
        display: inline-block;
        vertical-align: middle;
        width: 53px;

        img {
          min-width: 30px;
          max-width: 100%;
          height: auto;
        }
      }

      .subproductinfo {
        width: 560px;
        vertical-align: middle;
        display: inline-block;
        margin-left: 36px;

        .subproducttitle {
          font-size: 24px;
          font-weight: 500;
          color: #3d3d3d;
          line-height: 36px;
          cursor: default;
        }

        .subproducttitle:hover {
          color: #2f7ffc;
        }

        .subproducttext {
          margin-top: 12px;
          font-size: 20px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #9e9e9e;
          line-height: 32px;
        }
      }
    }
  }

  .advantage {
    margin-top: 90px;
    padding-bottom: 94px;

    .advantagelist {
      display: flex;
      justify-content: center;
      width: 80%;
      margin: auto;

      .advantagecard {
        width: 396px;
        height: 250px;
        padding-bottom: 33px;
        // box-sizing: border-box;
        text-align: center;

        .advantageimg {
          img {
            width: 56px;
          }
        }

        .advantagetitle {
          font-size: 24px;
          font-weight: 500;
          margin-top: 41px;
          line-height: 36px;
        }

        .advantageinfo {
          text-align: left;
          font-size: 16px;
          font-weight: 500;
          color: #9b9c9d;
          line-height: 30px;
          margin-top: 28px;
        }
      }
    }

    .thbottom {
      border-bottom: 1px solid #dcdcdc;
    }

    .thright {
      border-right: 1px solid #dcdcdc;
    }

    .thtop {
      padding-top: 72px;
    }

    .thpaddingleft {
      padding-left: 47px;
    }

    .thpaddingright {
      padding-right: 72px;
    }

    .thpaddingright2 {
      padding-right: 43px;
    }
  }

  .adhibition {
    width: 80%;
    padding: 96px;
    margin: 0 auto;
    box-sizing: border-box;
    .adhibitioninfo {
      border-bottom: 1px solid #d3d3d3;
      padding-bottom: 30px;

      .adhibitiontitle {
        font-size: 37px;
        font-weight: 500;
        color: #2f7ffc;
        line-height: 56px;
      }

      .adhibitiontext {
        margin-top: 30px;
        font-size: 24px;
        font-weight: 400;
        color: #616b80;
        line-height: 48px;
      }
    }

    .adhibitionimg {
      margin-top: 52px;

      img {
        width: 100%;
      }
    }

    .top {
      margin-top: 83px;
    }

    .top2 {
      margin-top: 41px;
    }
  }

  .case {
    margin-top: 98px;
    padding-bottom: 44px;
    .list {
      margin: 55px auto;
      display: flex;
      width: 80%;
      justify-content: space-between;

      .card:hover {
        .bebottom {
          border-radius: 0 0 20px 20px;
          width: 338px;
          height: 17px;
          position: absolute;
          margin-top: 17px;
          margin-left: 18px;
          background-color: #2f7ffc;
        }
      }

      .card {
        cursor: pointer;
        width: 430px;
        height: 316px;
        box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);
        border-radius: 10px;
        border: 1px solid #e8e8e8;
        padding: 28px 35px;
        box-sizing: border-box;

        .cardtitle {
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          margin-top: 23px;
        }

        .info {
          margin-top: 9px;
          font-size: 20px;
          font-weight: 400;
          color: #9e9e9e;
          line-height: 32px;
          height: 125px;
        }

        img {
          width: 71px;
        }
      }
    }
  }

  .title {
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
  }
}
</style>
